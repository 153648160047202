import { useEffect, useRef, useState } from "react";
import { Link, json, useNavigate } from "react-router-dom";
import { Select } from 'antd';
import BookingService from "../services/bookingService";
import { toast } from 'react-toastify';
import { Formik, Field, Form as FormikForm, ErrorMessage, Form } from 'formik';
import { Switch } from 'antd';
import * as Yup from "yup";
//import { Pagination } from "antd";
import ReactPaginate from "react-paginate";
import { format, parseISO, subMonths, startOfMonth, endOfMonth } from 'date-fns';
import { fr } from 'date-fns/locale';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import RoomService from "../services/RoomService";
import moment from 'moment';
import { getRole, logout } from "../auth/auth";
import { PDFDownloadLink } from '@react-pdf/renderer';
import { pdf } from "@react-pdf/renderer";
import { saveAs } from 'file-saver';
import Invoice from "../components/Invoice";
import UserService from "../services/userService";
import Sidebar from "../components/Sidebar";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faEdit, faEye, faCreditCard } from '@fortawesome/free-solid-svg-icons';
import StatisticsService from "../services/statisticsService";
import { DailyPayment } from "../components/DailyPayment";
import { GlobalPayment } from "../components/GlobalPayment";
import Navbar from "../components/Navbar";

// Validation schema avec Yup
const PasswordChangeSchema = Yup.object().shape({
  currentPassword: Yup.string().required('Mot de passe actuel est requis'),
  newPassword: Yup.string()
    .min(6, 'Le nouveau mot de passe doit comporter au moins 6 caractères')
    .required('Nouveau mot de passe est requis'),
});

const formatDate = (dateString) => {
  const date = parseISO(dateString);
  return format(date, "dd-MM-yyyy ' ' HH'h'", { locale: fr });
};

let nextId = 3;
const { Option } = Select

const AmountPaymentSchema = Yup.object().shape({
    amount: Yup.number(),
    //remainingAmount: Yup.number(),
    //totalPaidAmount: Yup.number(),
    //totalAmount: Yup.number()
        // .max(5000, 'Le prix ne doit pas dépasser 5000')
        // .required('Veuillez Entrer le montant')

});

const validationGlobalBilanSchema = Yup.object().shape({
    startDate: Yup.date().required('Date de début requise'),
    endDate: Yup.date().required('Date de fin requise').min(Yup.ref('startDate'), "La date de fin doit être postérieure à la date de début"),
    filterIncompletePayments: Yup.boolean().required('Veuillez indiquer si le paiement est incomplet'),
  });

const Booking = () => {
    const [bookings, setBookings] = useState([]);
    const [loading,  setLoading] = useState(false)
    const [isEditing,  setIsEditing] = useState(false)
    const [search,  setSearch] = useState("")
    const [rooms, setRooms] = useState([])
    //const [page,  setPage] = useState(0)
    const [totalAmount, setTotalAmount] = useState(0);
    const [bookingUpdateOrDelete, setBookingUpdateOrDelete] = useState(null);
    const closeModalToDelete = useRef(null)
    const closeModalToUpdate = useRef(null)
    const [nights, setNights] = useState(0);
    const [finalAmount, setFinalAmount] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [totalResult, setTotalResult] = useState(0);
    const [toggle, setToggle] = useState(false);
    const initialEndDate = new Date();
    const initialStartDate = subMonths(initialEndDate, 3);
    const [bookingPayment, setBookingPayment] = useState(null)
    const closePaymentModal = useRef(null);
    const closeModalToChangePassword = useRef(null)
    const closeModalToTerminate = useRef(null)
    const [totalNumberOfPage, setTotalNumberOfPage] = useState(0)
    const [totalNumberPerPage, setTotalNumberPerPage] = useState(8)

    const [dates, setDates] = useState([initialStartDate, initialEndDate]);

    //const navigate = useNavigate()

    const handleToggle = () => {
        setToggle(!toggle)
    }
    // const fetchReservationsWithDates = async (start, end) => {
    //     try {
    //       const formattedStart = format(startOfMonth(start), 'yyyy-MM-dd');
    //       const formattedEnd = format(endOfMonth(end), 'yyyy-MM-dd');
    //       const data = {
    //         search: "",
    //         numberPerPage: 4,
    //         startCreatedAt: formattedStart,
    //         endCreatedAt: formattedEnd
    //       }
    //       const response = await BookingService.getBookings(data);
    //       setBookings(response.result);
    //     } catch (error) {
    //       console.error('Error fetching reservations:', error);
    //     }
    // };
    const handleDateChanges = (dates) => {
        setCurrentPage(0);
        setDates(dates);
    };
    

    const listAllBookings = async (search, page, numberPerPage, dates) => {
        const data = {}
        data.search = search;
        data.page = page;
        data.numberPerPage = numberPerPage;
        data.startCreatedAt = dates[0]
        data.endCreatedAt = dates[1]
        setLoading(true)
        try {
            const response = await BookingService.getBookings(data)
            setLoading(false)
            //console.log("see the list All Bookings ==> ", response)
            setBookings(response.result);
            setTotalNumberOfPage(response.totalNumberOfPage)
            //setCurrentPage(response.page)
            setTotalResult(response.totalResult)
        } catch (error) {
            console.log('Error fetching items: ',error)
        }
    }


    // useEffect(() => {
    //     listAllBookings(search, currentPage)
    // }, [search, currentPage])

    useEffect(() => {
        listAllBookings(search, currentPage, totalNumberPerPage, dates)
    }, [search, currentPage, totalNumberPerPage, dates])

    // useEffect(() => {
    //     listAllBookings(search, currentPage)
    // }, [currentPage])

    const bookingAdelete = async (reference) => {
        //console.log('afficher la reference ==>', bookingUpdateOrDelete)
        setLoading(true)
        const response = await BookingService.deleteABooking(reference)
        setLoading(false)
        setSearch("")
        setBookingUpdateOrDelete(null)
        console.log('bouton de fermeture  de delete customer ==> ', closeModalToDelete.current)
        closeModalToDelete.current.click()
        listAllBookings(search, currentPage, totalNumberPerPage, dates);
        setCurrentPage(0)
        toast("La reservation a été Supprimé avec succès")
    }

    const listAllRooms = async () => {
        try {
            setLoading(true)
            const response = await RoomService.getListAllRooms();
            setLoading(false)
            setRooms(response)
        } catch (error) {
            console.error('Erreur lors de la récupération des clients:', error);
        }
    }

    useEffect(() => {
        listAllRooms()
    }, [])
    //console.log('What booking update or delete ==>',bookingUpdateOrDelete.reference)
    // const handleChangeCurrentPage = (page) => {
    //     setCurrentPage(page - 1)
    // }
    const getBookingInformationsDetail = async (reference) => {
        //console.log("Detail ===> user",reference)
        setLoading(true)
        const response =  await BookingService.bookingInformationsDetail(reference)
        setLoading(false)
        setBookingUpdateOrDelete(response)
        setBookingPayment(response)
        //console.log(`detail du user ${response}`)
    }

    const getBookingInformationsDetailToPayment = async (reference) => {
        console.log("Detail ===> user",reference)
        setLoading(true)
        const response =  await BookingService.bookingInformationsDetail(reference)
        setLoading(false)
        //setBookingUpdateOrDelete(response)
        setBookingPayment(response)
        //console.log(`detail du user ${response}`)
    }
    //console.log(`Pour le paiement ==> ${bookingPayment}`)
    
    const dailyPayment = async() => {
        try {
            setLoading(true);
            const responseDailyPayment = await StatisticsService.dailyPayment()
            if (responseDailyPayment.errorResponse) {
                toast.error(responseDailyPayment.ErrorMessage)
            } else {
                const docDailyPayment = <DailyPayment dailyPayment={responseDailyPayment} />
                pdf(docDailyPayment).toBlob().then((blob ) => {
                    
                    saveAs(blob, `Bilan_Journalier_du_${responseDailyPayment.printDate}.pdf`);
                });
                toast("Le bilan journalier a été imprimé avec succès")    
            }
        } catch (error) {
            console.log('facture journalière ',error)
        } finally {
            setLoading(false)
        }
    }

    const paymentInstallment = async (values, {resetForm}) => {
        //alert(JSON.stringify(values, null, 4));
        setLoading(true);
        const response = await BookingService.paymentInstallment(bookingUpdateOrDelete.reference, values);
        setLoading(false)
        setBookingUpdateOrDelete(response)
        const docFacture = <Invoice reservation={response} />
        pdf(docFacture).toBlob().then((blob) => saveAs(blob, `Facture_de_${response.customer.lastName}_${response.createdAt}.pdf`))
        listAllBookings(search, currentPage, totalNumberPerPage)
        closePaymentModal.current.click()
        resetForm()
        toast.success('Le paiement est éffectué avec succès')
    }



    const handleSubmitToUpdateBooking = async (values) => {
        //alert(JSON.stringify(values, null, 4))
        setLoading(true);
        try {
            const response = await BookingService.updateBookingInformation(bookingUpdateOrDelete.reference, values);
            if (response.errorResponse) {
                toast.error(response.message)
            } else {
                listAllBookings(search, currentPage, totalNumberPerPage, dates)
                closeModalToUpdate.current.click()
                toast("Le reservation a été Modifié avec succès")
            }
            
        } catch (error) {
            toast.warning("Veuillez démarrer le serveur")
        } finally {
            setLoading(false);
        }
    }

    const handleDateChange = (values) => {
        const { startAt, endAt, price, discount } = values;
        if (startAt && endAt) {
          const calculatedNights = calculateNights(startAt, endAt);
          setNights(calculatedNights);
          const amount = calculatedNights * (price || 0);
          setTotalAmount(amount);
          setFinalAmount(amount - (discount || 0));
        }
    };

    const calculateNights = (startAt, endAt) => {
        const startDate = new Date(startAt);
        const endDate = new Date(endAt);
        const differenceInTime = endDate - startDate;
        const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24));
        return differenceInDays;
    };

    const calculteAmounts = (values) => {
        const nights =calculateNights(values.startAt, values.endAt);
        const totalAmount = nights * values.price;
        const finalAmount = totalAmount - (values.discount);
        return { nights, totalAmount, finalAmount }
    }

    const handlePriceChange = (values) => {
        const { price, discount } = values;
        const amount = nights * (price || 0);
        setTotalAmount(amount);
        setFinalAmount(amount - (discount || 0));
    };

    const handleDiscountChange = (values) => {
        const { discount } = values;
        setFinalAmount(totalAmount - (discount || 0));
    };

    const itemRender = (current, type, originalElement) => {
        if (type === 'page') {
          return <a>{current}</a>;
        }
        
        return originalElement;
    };

    const changePassword = async (values, {resetForm}) => {
        //alert(values)
        try {
            setLoading(true)
            const response = await UserService.updatePassword(values);
            if (response.errorResponse) {
                toast.error(response.message)
            } else {
                toast.success('Votre Mot de passe a été modifié avec succès')
                resetForm()
                closeModalToChangePassword.current.click()
            }
        } catch (error) {
            toast.error("Une érreur inattendue s'est produite lors de la modification du mot de passe")
            
        } finally {
            setLoading(false)
        }
    }

    const handleSearchBooking = (event) => {
        const searchBooking = event.target.value;
        setSearch(searchBooking)
        setCurrentPage(0)
    }

    const handleChangeCurrentPage = async(data) => {
        let currentPageu = data.selected;
        setCurrentPage(currentPageu)
        return ;
    }

    const handleBookingPerPageChange = (e) => {
        setCurrentPage(0)
        setTotalNumberPerPage(Number(e.target.value));
        return ;
    }
    //console.log("Voir la facture => ", bookingUpdateOrDelete)

    const globalBilanWithAndWithoutIncompletePayment = async (value) => {
        try {
            const responsePaymentGlobalSummary = await StatisticsService.globalPayment(value);
            console.log("Globale ==>> ", responsePaymentGlobalSummary)
            if (responsePaymentGlobalSummary.errorResponse) {
                toast.error(responsePaymentGlobalSummary.message)
            } else {
                const docPaymentGlobalSummary = <GlobalPayment paymentGlobal={responsePaymentGlobalSummary} />
                pdf(docPaymentGlobalSummary).toBlob().then((blob ) => {
                    
                    saveAs(blob, `Bilan_global_du_${responsePaymentGlobalSummary.printDate}.pdf`);
                });
                toast("Le bilan global a été imprimé avec succès")    
            }
            //alert(JSON.stringify(value, null,4))
        } catch (error) {
            toast.error("Veuillez redémarer le serveur")
        }

    }

    const confirmTerminate = async(reference) => {
        try {
            const responseToTerminateBooking = await BookingService.terminateABooking(reference)
            if (responseToTerminateBooking.errorResponse) {
                toast.error(responseToTerminateBooking.message)
            } else {
                listAllBookings(search, currentPage, totalNumberPerPage, dates)
                toast.success("La réservation a été termininé avec succeès")
                closeModalToTerminate.current.click()
            }
        } catch (error) {
            toast.error("Veuillez redémarer le serveur")
        }

    }
    return (
        <>
            <Navbar />
            <div className="g-sidenav-show  bg-gray-100">
                <Sidebar />
                {/* <aside className="sidenav navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl my-3 fixed-start ms-3 " id="sidenav-main">
                    <div className="sidenav-header">
                        <i className="fas fa-times p-3 cursor-pointer text-secondary opacity-5 position-absolute end-0 top-0 d-none d-xl-none" aria-hidden="true" id="iconSidenav"></i>
                        <a className="navbar-brand m-0" href=" https://demos.creative-tim.com/soft-ui-dashboard/pages/dashboard.html " >
                            <img src="../assets/img/logo-ct-dark.png" className="navbar-brand-img h-100" alt="main_logo" />
                            <span className="ms-1 font-weight-bold">Soft UI Dashboard</span>
                        </a>
                    </div>
                    <hr className="horizontal dark mt-0"/>
                    <div className="collapse navbar-collapse  w-auto " id="sidenav-collapse-main">
                        <ul className="navbar-nav">
                            <li className="nav-item">
                                <Link className="nav-link  active" to="/rooms">
                                    <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
                                    <i class="fa-solid fa-bed"></i>
                                    </div>
                                    <span className="nav-link-text ms-1">Chambres</span>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link  active" to="/customer">
                                    <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
                                        <i class="fa fa-user" aria-hidden="true"></i>
                                    </div>
                                    <span className="nav-link-text ms-1">Clients</span>
                                </Link>
                            </li>
                            {getRole() == "ROLE_ADMIN" && (
                                <li className="nav-item">
                                    <Link className="nav-link  active" to="/workers">
                                        <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
                                            <i class="fa fa-user" aria-hidden="true"></i>
                                        </div>
                                        <span className="nav-link-text ms-1">Employé</span>
                                    </Link>
                                </li>
                            )}
                            <li className="nav-item">
                                <Link className="nav-link  " to="/bookings">
                                    <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
                                        <i class="fa fa-ticket" aria-hidden="true"></i>
                                    </div>
                                    <span className="nav-link-text ms-1"> Reservation</span>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link  " to="/create-a-booking">
                                    <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
                                        <i class="fa fa-ticket" aria-hidden="true"></i>
                                    </div>
                                    <span className="nav-link-text ms-1"> Créer une reservation</span>
                                </Link>
                            </li>
                            <li className="nav-item mt-3">
                                <h6 className="ps-4 ms-2 text-uppercase text-xs font-weight-bolder opacity-6">Account pages</h6>
                            </li>

                            <li className="nav-item">
                                
                            </li>
                            <div className="d-flex align-items-center justify-content-center">
                                <button 
                                        className="nav-link-text ms-1"
                                        onClick={() => {logout(); navigate('/login')}}    
                                    >
                                    Déconnecter
                                </button>
                            </div>
                            <div className="d-flex align-items-center justify-content-center">
                                <button 
                                    className="nav-link-text ms-1"
                                    data-bs-toggle="modal" 
                                    data-bs-target="#staticChangePasswordBackdrop"
                                >
                                    Changer mot de passer
                                </button>
                            </div>
                        </ul>
                    </div>
                    <div className="sidenav-footer mx-3 ">
                        <div className="card card-background shadow-none card-background-mask-secondary" id="sidenavCard">
                            <div className="full-background" style={{backgroundImage: "url(" +"./assets/img/curved-images/white-curved.jpg" + ")" }}></div>
                            <div className="card-body text-start p-3 w-100">
                                <div className="icon icon-shape icon-sm bg-white shadow text-center mb-3 d-flex align-items-center justify-content-center border-radius-md">
                                    <i className="ni ni-diamond text-dark text-gradient text-lg top-0" aria-hidden="true" id="sidenavCardIcon"></i>
                                </div>
                                <div className="docs-info">
                                    <h6 className="text-white up mb-0">Need help?</h6>
                                    <p className="text-xs font-weight-bold">Please check our docs</p>
                                    <a 
                                        //href="https://www.creative-tim.com/learning-lab/bootstrap/license/soft-ui-dashboard" 
                                        //classNametarget="_blank"="btn btn-white btn-sm w-100 mb-0"
                                    >
                                        Documentation
                                    </a>
                                </div>
                            </div>
                        </div>
                        <a className="btn bg-gradient-primary mt-3 w-100" href="https://www.creative-tim.com/product/soft-ui-dashboard-pro?ref=sidebarfree">Upgrade to pro</a>
                    </div>
                </aside> */}
            <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
                {/* Navbar */}
                {/* <nav className="navbar navbar-main navbar-expand-lg px-0 mx-4 shadow-none border-radius-xl" id="navbarBlur" navbar-scroll="true">
                <div className="container-fluid py-1 px-3">
                    <nav aria-label="breadcrumb">
                    <ol className="breadcrumb bg-transparent mb-0 pb-0 pt-1 px-0 me-sm-6 me-5">
                        <li className="breadcrumb-item text-sm"><a className="opacity-5 text-dark" href="/#">Pages</a></li>
                        <li className="breadcrumb-item text-sm text-dark active" aria-current="page">Tables</li>
                    </ol>
                    <h6 className="font-weight-bolder mb-0">Tables</h6>
                    </nav>
                    <div className="collapse navbar-collapse mt-sm-0 mt-2 me-md-0 me-sm-4" id="navbar">
                    <div className="ms-md-auto pe-md-3 d-flex align-items-center">
                        <div className="input-group">
                        <span className="input-group-text text-body"><i className="fas fa-search" aria-hidden="true"></i></span>
                        <input type="text" className="form-control" placeholder="Type here..." />
                        </div>
                    </div>
                    <ul className="navbar-nav  justify-content-end">
                        <li className="nav-item d-flex align-items-center">
                        <a 
                            className="btn btn-outline-primary btn-sm mb-0 me-3" 
                            href="https://www.creative-tim.com/builder?ref=navbar-soft-ui-dashboard"
                        >
                            Online Builder
                        </a>
                        </li>
                        <li className="nav-item d-flex align-items-center">
                        <a href="/#" className="nav-link text-body font-weight-bold px-0">
                            <i className="fa fa-user me-sm-1"></i>
                            <span className="d-sm-inline d-none">Sign In</span>
                        </a>
                        </li>
                        <li className="nav-item d-xl-none ps-3 d-flex align-items-center">
                        <a href="/#" className="nav-link text-body p-0" id="iconNavbarSidenav">
                            <div className="sidenav-toggler-inner">
                            <i className="sidenav-toggler-line"></i>
                            <i className="sidenav-toggler-line"></i>
                            <i className="sidenav-toggler-line"></i>
                            </div>
                        </a>
                        </li>
                        <li className="nav-item px-3 d-flex align-items-center">
                        <a 
                            href="/#" 
                            className="nav-link text-body p-0"
                        >
                            <i className="fa fa-cog fixed-plugin-button-nav cursor-pointer"></i>
                        </a>
                        </li>
                        <li className="nav-item dropdown pe-2 d-flex align-items-center">
                        <a 
                            href="/#" 
                            className="nav-link text-body p-0" 
                            id="dropdownMenuButton" 
                            data-bs-toggle="dropdown" 
                            aria-expanded="false"
                        >
                            <i className="fa fa-bell cursor-pointer"></i>
                        </a>
                        <ul className="dropdown-menu  dropdown-menu-end  px-2 py-3 me-sm-n4" aria-labelledby="dropdownMenuButton">
                            <li className="mb-2">
                            <a className="dropdown-item border-radius-md" href="/#">
                                <div className="d-flex py-1">
                                <div className="my-auto">
                                    <img 
                                        alt=""
                                        src="../assets/img/team-2.jpg" 
                                        className="avatar avatar-sm  me-3 "
                                    />
                                </div>
                                <div className="d-flex flex-column justify-content-center">
                                    <h6 className="text-sm font-weight-normal mb-1">
                                    <span className="font-weight-bold">New message</span> from Laur
                                    </h6>
                                    <p className="text-xs text-secondary mb-0 ">
                                    <i className="fa fa-clock me-1"></i>
                                    13 minutes ago
                                    </p>
                                </div>
                                </div>
                            </a>
                            </li>
                            <li className="mb-2">
                            <a className="dropdown-item border-radius-md" href="/#">
                                <div className="d-flex py-1">
                                <div className="my-auto">
                                    <img 
                                        alt=""
                                        src="../assets/img/small-logos/logo-spotify.svg" 
                                        className="avatar avatar-sm bg-gradient-dark  me-3 "
                                    />
                                </div>
                                <div className="d-flex flex-column justify-content-center">
                                    <h6 className="text-sm font-weight-normal mb-1">
                                    <span className="font-weight-bold">New album</span> by Travis Scott
                                    </h6>
                                    <p className="text-xs text-secondary mb-0 ">
                                    <i className="fa fa-clock me-1"></i>
                                    1 day
                                    </p>
                                </div>
                                </div>
                            </a>
                            </li>
                            <li>
                            <a className="dropdown-item border-radius-md" href="/#">
                                <div className="d-flex py-1">
                                <div className="avatar avatar-sm bg-gradient-secondary  me-3  my-auto">
                                    <svg width="12px" height="12px" viewBox="0 0 43 36" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                                    <title>credit-card</title>
                                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                        <g transform="translate(-2169.000000, -745.000000)" fill="#FFFFFF" fillRule="nonzero">
                                        <g transform="translate(1716.000000, 291.000000)">
                                            <g transform="translate(453.000000, 454.000000)">
                                            <path className="color-background" d="M43,10.7482083 L43,3.58333333 C43,1.60354167 41.3964583,0 39.4166667,0 L3.58333333,0 C1.60354167,0 0,1.60354167 0,3.58333333 L0,10.7482083 L43,10.7482083 Z" opacity="0.593633743"></path>
                                            <path className="color-background" d="M0,16.125 L0,32.25 C0,34.2297917 1.60354167,35.8333333 3.58333333,35.8333333 L39.4166667,35.8333333 C41.3964583,35.8333333 43,34.2297917 43,32.25 L43,16.125 L0,16.125 Z M19.7083333,26.875 L7.16666667,26.875 L7.16666667,23.2916667 L19.7083333,23.2916667 L19.7083333,26.875 Z M35.8333333,26.875 L28.6666667,26.875 L28.6666667,23.2916667 L35.8333333,23.2916667 L35.8333333,26.875 Z"></path>
                                            </g>
                                        </g>
                                        </g>
                                    </g>
                                    </svg>
                                </div>
                                <div className="d-flex flex-column justify-content-center">
                                    <h6 className="text-sm font-weight-normal mb-1">
                                    Payment successfully completed
                                    </h6>
                                    <p className="text-xs text-secondary mb-0 ">
                                    <i className="fa fa-clock me-1"></i>
                                    2 days
                                    </p>
                                </div>
                                </div>
                            </a>
                            </li>
                        </ul>
                        </li>
                    </ul>
                    </div>
                </div>
                </nav> */}
                {/* End Navbar */}
                <div class="d-flex bd-highlight align-items-center justify-content-between namePhoneSearch mb-3">
                    <div class="p-2 bd-highlight">
                        <div className="form-check form-switch">
                            <Switch 
                                
                                //checked={isNewClient} 
                                onChange={handleToggle} 
                            />
                            <label className="form-check-label" htmlFor="flexSwitchCheckNewClient">
                                Filter Par la date
                            </label>
                            </div>
                        </div>
                        <div>
                            <div>
                                Date de début
                            </div>
                            <DatePicker
                                disabled={!toggle}
                                selected={dates[0]}
                                onChange={(date) => handleDateChanges([date, dates[1]])}
                                dateFormat="yyyy-MM-dd HH:mm"
                                showTimeSelect
                                placeholderText="Start Date"
                            />
                            
                            {/* <DatePicker
                                disabled={!toggle}
                                selected={createdDateAt}
                                onChange={(date) => setCreatedDateAt(date)}
                                placeholderText="Date de début"
                            /> */}
                        </div>
                        <div>
                            <div>
                                Date de fin
                            </div>
                            <DatePicker
                                disabled={!toggle}
                                selected={dates[1]}
                                onChange={(date) => handleDateChanges([dates[0], date])}
                                dateFormat="yyyy-MM-dd HH:mm"
                                showTimeSelect
                                placeholderText="End Date"
                            />
                        </div>
                    <div class="p-2 bd-highlight">
                        <div class="ms-md-auto d-flex align-items-center">
                            <div class="input-group">
                                <span class="input-group-text text-body">
                                    <i class="fas fa-search" aria-hidden="true"></i>
                                </span>
                                <input 
                                    type="text" 
                                    
                                    className="form-control" 
                                    placeholder="Chambre, nom, ou téléphone"
                                    onChange={handleSearchBooking}
                                />
                                {/* <input 
                                    type="text" 
                                    
                                    className="form-control" 
                                    placeholder="Rechercher la reservation"
                                    onChange={(e) => setSearch(e.target.value)}
                                /> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="d-flex justify-content-between bd-highlight p-2">
                    <div>
                        <button className="btn btn-primary" onClick={dailyPayment}>
                            Bilan journalier
                        </button>
                    </div>
                    {getRole() == "ROLE_ADMIN" && (
                        <div>
                            <button 
                                className="btn btn-info"
                                data-bs-toggle="modal"
                                data-bs-target="#staticGlobaleBilanBackdrop"
                            >
                                Bilan global
                            </button>
                        </div>
                    )}
                </div>
                <div className="d-flex justify-content-center">
                    {/* <div className="d-flex align-items-center">Nombre de reservations</div> */}
                    <div>
                        <select className="h3 form-select" value={totalNumberPerPage} onChange={handleBookingPerPageChange}>
                            <option selected>Nombre de reservations</option>
                            <option value={5}>5</option>
                            <option value={9}>9</option>
                            <option value={10}>10</option>
                            <option value={15}>15</option>

                        </select>
                    </div>
                </div>
                <div className="container-fluid py-4">
                    <div className="row">
                        <div className="col-12">
                            <div className="card mb-4">
                                <div className="card-header pb-0">
                                    <h6>Liste des Reservation</h6>
                                </div>
                                <div className="card-body px-0 pt-0 pb-2">
                                    <div className="table-responsive p-0">
                                        <table className="table align-items-center mb-0">
                                        <thead>
                                            <tr>
                                            <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Chambres</th>
                                            <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Reférences</th>
                                            {/* <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Utilisateur</th> */}
                                            <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Client</th>
                                            {/* <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Montant restant</th>
                                            <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Montant Payé</th> */}
                                            <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Bilan</th>
                                            <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Date</th>
                                            <th className="text-secondary opacity-7"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {bookings.map(booking => (
                                                <tr>
                                                    <td>
                                                        <div className="d-flex px-2 py-1">
                                                            {/* <div>
                                                                <img 
                                                                    src="../assets/img/team-2.jpg" 
                                                                    className="avatar avatar-sm me-3" 
                                                                    alt="user1"
                                                                />
                                                            </div> */}
                                                            <div className="d-flex flex-column justify-content-center">
                                                                <h6 className="mb-0 text-sm">{booking.room.number}</h6>
                                                                <p className="text-xs text-secondary mb-0">{booking.room.location}</p>
                                                                <p className="text-xs text-secondary mb-0">{booking.room.price}</p>
                                                                <p className="text-xs text-secondary mb-0">{booking.room.type}</p>
                                                                <p className="text-xs text-secondary mb-0">{booking.user.username}</p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="align-middle text-center text-sm">
                                                        <p className="text-xs font-weight-bold mb-0">{booking.reference}</p>
                                                    </td>
                                                    <td className="align-middle text-center text-sm">
                                                        <div className="d-flex flex-column justify-content-center customer">
                                                            <p className="font-weight-bold">{booking.customer.lastName}</p>
                                                            <p className="font-weight-bold">{booking.customer.firstName}</p>
                                                            <p className="font-weight-bold fs-4">{booking.customer.phoneNumber}</p>
                                                        </div>
                                                    </td>
                                                    <td className="align-middle text-center text-sm">
                                                        <p className="text-xs font-weight-bold mb-0 text-danger fs-5" >Reste: {booking?.remainingAmount}</p>
                                                        <p className="text-xs font-weight-bold mb-0 text-primary fs-5" >Payé : {booking?.totalPaidAmount}</p>
                                                        <p className="text-xs font-weight-bold mb-0 text-danger fs-5" >Total : {booking?.actualPrice}</p>
                                                    </td>
                                                {/* <td className="align-middle text-center text-sm">
                                                    <p className="text-xs font-weight-bold mb-0 text-primary fs-4" >{booking?.totalPaidAmount}</p>
                                                </td> */}
                                                    <td className="align-middle text-center text-sm">
                                                        <p className="text-xs font-weight-bold mb-0 fs-5" >De {formatDate(booking?.startAt)}</p>
                                                        <p className="text-xs font-weight-bold mb-0 fs-5" >A {formatDate(booking?.endAt)}</p>
                                                    </td>
                                                    <td className="align-middle">
                                                    
                                                        {/* <i 
                                                            class="fas fa-edit fa-2x" 
                                                            style={{marginRight: "32px"}} 
                                                            data-bs-toggle="modal" 
                                                            data-bs-target="#updateUserModal" 
                                                            onClick={() => {getBookingInformationsDetail(booking.reference)}}
                                                        />

                                                        <i 
                                                            style={{marginRight: "32px"}}
                                                            class="fa fa-trash editOrUpdate fa-2x" 
                                                            onClick={() => {setBookingUpdateOrDelete(booking)}}
                                                            aria-hidden="true"
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#staticBackdrop"
                                                        />
                                                        <i 
                                                            class="fa fa-eye fa-2x"
                                                            aria-hidden="true"
                                                            data-bs-toggle="modal" 
                                                            data-bs-target="#reservationFactureModal"
                                                            onClick={() =>getBookingInformationsDetail(booking.reference) }
                                                        /> */}
                                                        <FontAwesomeIcon 
                                                            icon={faEdit} 
                                                            style={{marginRight: "32px"}}
                                                            size="2x" 
                                                            data-bs-toggle="modal" 
                                                            data-bs-target="#updateUserModal" 
                                                            onClick={() => {getBookingInformationsDetail(booking.reference)}}
                                                        />
                                                        {getRole() == "ROLE_ADMIN" && (
                                                            <FontAwesomeIcon 
                                                                icon={faTrash} 
                                                                style={{marginRight: "32px"}}
                                                                size="2x" 
                                                                data-bs-toggle="modal"
                                                                data-bs-target="#staticBackdrop"
                                                                onClick={() => {setBookingUpdateOrDelete(booking)}}
                                                            />
                                                        )}
                                                        <FontAwesomeIcon 
                                                            icon={faEye} 
                                                            style={{marginRight: "32px"}}
                                                            size="2x" 
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#reservationFactureModal"
                                                            onClick={() => {getBookingInformationsDetail(booking.reference)}}
                                                        />
                                                    </td>
                                                    <td>
                                                        <button 
                                                            type="button" 
                                                            className="btn btn-info" 
                                                            disabled={!(new Date() >= new Date(booking.startAt) && new Date() <= new Date(booking.endAt))}
                                                            data-bs-toggle="modal" 
                                                            data-bs-target="#terminateModal"
                                                            onClick={() => { setBookingUpdateOrDelete(booking) }}
                                                        >
                                                            Terminer
                                                        </button>
                                                    </td>
                                                    <td>
                                                        <button 
                                                            type="button" 
                                                            className="btn btn-primary" 
                                                            data-bs-toggle="modal" 
                                                            data-bs-target="#paymentModal"
                                                            onClick={() => {setBookingUpdateOrDelete(booking)}}
                                                            //onClick={() => {getBookingInformationsDetailToPayment(booking.reference)}}
                                                        >
                                                            Payer
                                                        </button>
                                                    </td>
                                                    
                                                </tr>
                                                ))}
                                            {/* <tr>
                                                <td>
                                                    <div className="d-flex px-2 py-1">
                                                    <div>
                                                        <img 
                                                            src="../assets/img/team-3.jpg" 
                                                            className="avatar avatar-sm me-3" 
                                                            alt="user2"
                                                        />
                                                    </div>
                                                    <div className="d-flex flex-column justify-content-center">
                                                        <h6 className="mb-0 text-sm">Alexa Liras</h6>
                                                        <p className="text-xs text-secondary mb-0">alexa@creative-tim.com</p>
                                                    </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <p className="text-xs font-weight-bold mb-0">Programator</p>
                                                    <p className="text-xs text-secondary mb-0">Developer</p>
                                                </td>
                                                <td className="align-middle text-center text-sm">
                                                    <span className="badge badge-sm bg-gradient-secondary">Offline</span>
                                                </td>
                                                <td className="align-middle text-center">
                                                    <span className="text-secondary text-xs font-weight-bold">11/01/19</span>
                                                </td>
                                                <td className="align-middle">
                                                    <a href="/#" className="text-secondary font-weight-bold text-xs" data-toggle="tooltip" data-original-title="Edit user">
                                                    <i class="fas fa-edit"></i>
                                                    </a>
                                                </td>
                                            </tr> */}
                                            {/* <tr>
                                                <td>
                                                    <div className="d-flex px-2 py-1">
                                                    <div>
                                                        <img 
                                                            src="../assets/img/team-4.jpg" 
                                                            className="avatar avatar-sm me-3" 
                                                            alt="user3"
                                                        />
                                                    </div>
                                                    <div className="d-flex flex-column justify-content-center">
                                                        <h6 className="mb-0 text-sm">Laurent Perrier</h6>
                                                        <p className="text-xs text-secondary mb-0">laurent@creative-tim.com</p>
                                                    </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <p className="text-xs font-weight-bold mb-0">Executive</p>
                                                    <p className="text-xs text-secondary mb-0">Projects</p>
                                                </td>
                                                <td className="align-middle text-center text-sm">
                                                    <span className="badge badge-sm bg-gradient-success">Online</span>
                                                </td>
                                                <td className="align-middle text-center">
                                                    <span className="text-secondary text-xs font-weight-bold">19/09/17</span>
                                                </td>
                                                <td className="align-middle">
                                                    <a href="/#" className="text-secondary font-weight-bold text-xs" data-toggle="tooltip" data-original-title="Edit user">
                                                        <i class="fas fa-edit" style={{width: "16px", height: "16px"}}></i>
                                                    </a>
                                                    <div>
                                                        <i class="fa fa-trash" aria-hidden="true"></i>
                                                    </div>
                                                </td>
                                            </tr> */}
                                            {/* <tr>
                                                <td>
                                                    <div className="d-flex px-2 py-1">
                                                    <div>
                                                        <img 
                                                            src="../assets/img/team-3.jpg" 
                                                            className="avatar avatar-sm me-3" 
                                                            alt="user4"
                                                        />
                                                    </div>
                                                    <div className="d-flex flex-column justify-content-center">
                                                        <h6 className="mb-0 text-sm">Michael Levi</h6>
                                                        <p className="text-xs text-secondary mb-0">michael@creative-tim.com</p>
                                                    </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <p className="text-xs font-weight-bold mb-0">Programator</p>
                                                    <p className="text-xs text-secondary mb-0">Developer</p>
                                                </td>
                                                <td className="align-middle text-center text-sm">
                                                    <span className="badge badge-sm bg-gradient-success">Online</span>
                                                </td>
                                                <td className="align-middle text-center">
                                                    <span className="text-secondary text-xs font-weight-bold">24/12/08</span>
                                                </td>
                                                <td className="align-middle">
                                                    <a 
                                                        href="/#" 
                                                        className="text-secondary font-weight-bold text-xs" 
                                                        data-toggle="tooltip" 
                                                        data-original-title="Edit user"
                                                    >
                                                    <i class="fas fa-edit"></i>
                                                    </a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div className="d-flex px-2 py-1">
                                                    <div>
                                                        <img 
                                                            src="../assets/img/team-2.jpg" 
                                                            className="avatar avatar-sm me-3" 
                                                            alt="user5"
                                                        />
                                                    </div>
                                                    <div className="d-flex flex-column justify-content-center">
                                                        <h6 className="mb-0 text-sm">Richard Gran</h6>
                                                        <p className="text-xs text-secondary mb-0">richard@creative-tim.com</p>
                                                    </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <p className="text-xs font-weight-bold mb-0">Manager</p>
                                                    <p className="text-xs text-secondary mb-0">Executive</p>
                                                </td>
                                                <td className="align-middle text-center text-sm">
                                                    <span className="badge badge-sm bg-gradient-secondary">Offline</span>
                                                </td>
                                                <td className="align-middle text-center">
                                                    <span className="text-secondary text-xs font-weight-bold">04/10/21</span>
                                                </td>
                                                <td className="align-middle">
                                                    <a href={"javascript"} className="text-secondary font-weight-bold text-xs" data-toggle="tooltip" data-original-title="Edit user">
                                                    <i class="fas fa-edit"></i>
                                                    </a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div className="d-flex px-2 py-1">
                                                    <div>
                                                        <img 
                                                            src="../assets/img/team-4.jpg" 
                                                            className="avatar avatar-sm me-3" 
                                                            alt="user6"
                                                        />
                                                    </div>
                                                    <div className="d-flex flex-column justify-content-center">
                                                        <h6 className="mb-0 text-sm">Miriam Eric</h6>
                                                        <p className="text-xs text-secondary mb-0">miriam@creative-tim.com</p>
                                                    </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <p className="text-xs font-weight-bold mb-0">Programtor</p>
                                                    <p className="text-xs text-secondary mb-0">Developer</p>
                                                </td>
                                                <td className="align-middle text-center text-sm">
                                                    <span className="badge badge-sm bg-gradient-secondary">Offline</span>
                                                </td>
                                                <td className="align-middle text-center">
                                                    <span className="text-secondary text-xs font-weight-bold">14/09/20</span>
                                                </td>
                                                <td className="align-middle">
                                                    <a href={"javascript"} className="text-secondary font-weight-bold text-xs" data-toggle="tooltip" data-original-title="Edit user">
                                                    <i class="fas fa-edit"></i>
                                                    </a>
                                                </td>
                                            </tr> */}
                                        </tbody>
                                        </table>
                                        <div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
                                            <div class="offcanvas-header">
                                                <h5 class="offcanvas-title" id="offcanvasExampleLabel">Offcanvas</h5>
                                                <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                                            </div>
                                            <div class="offcanvas-body">
                                                <div>
                                                Some text as placeholder. In real life you can have the elements you have chosen. Like, text, images, lists, etc.
                                                </div>
                                                <div class="dropdown mt-3">
                                                <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown">
                                                    Dropdown button
                                                </button>
                                                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                    <li><a class="dropdown-item" href="#">Action</a></li>
                                                    <li><a class="dropdown-item" href="#">Another action</a></li>
                                                    <li><a class="dropdown-item" href="#">Something else here</a></li>
                                                </ul>
                                                </div>
                                            </div>
                                        </div>
                            
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <button type="button" class="btn btn-outline-info" data-bs-toggle="modal" data-bs-target="#exampleModalAccordeon">
                                    Créer une Reservation
                                </button>
                                <div class="modal fade" id="exampleModalAccordeon" tabindex="-1" aria-labelledby="exampleModalAccordeon">
                                    <div class="modal-dialog">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h5 class="modal-title" id="exampleModalLabel">Creer une reservation</h5>
                                                <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div class="modal-body">
                                                <div class="accordion" id="accordionExample">
                                                    <div class="accordion-item">
                                                        <h2 class="accordion-header" id="headingOne">
                                                            <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                                Client
                                                            </button>
                                                        </h2>
                                                        <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                            <div class="accordion-body">
                                                                <form>
                                                                    <div class="form-group">
                                                                        <label for="recipient-name" class="col-form-label">Nom</label>
                                                                        <input type="text" class="form-control" id="recipient-name" />
                                                                    </div>
                                                                    <div class="form-group">
                                                                        <label for="recipient-name" class="col-form-label">Prenom</label>
                                                                        <input type="text" class="form-control" id="recipient-name" />
                                                                    </div>
                                                                    <div class="form-group">
                                                                        <select class="form-select" aria-label="Default select example">
                                                                            <option selected>Cliquer ici pour choisir le type de client</option>
                                                                            <option value="1">VIP</option>
                                                                            <option value="2">SIMPLLE</option>
                                                                            <option value="3">EXLU</option>
                                                                        </select>
                                                                    </div>
                                                                    <div class="form-group">
                                                                        <select class="form-select" aria-label="Default select example">
                                                                            <option selected>Cliquer ici pour choisir le genre</option>
                                                                            <option value="1">HOMME</option>
                                                                            <option value="2">FEMME</option>
                                                                        </select>
                                                                    </div>
                                                                    <div class="form-group">
                                                                        <label for="recipient-name" class="col-form-label">Anniversaire</label>
                                                                        <input type="date" class="form-control" id="recipient-name" />
                                                                    </div>
                                                                    <div class="form-group">
                                                                        <label 
                                                                            htmlFor="recipient-name" 
                                                                            class="col-form-label"
                                                                        >
                                                                            Telephone
                                                                        </label>
                                                                        <input type="number" class="form-control" id="recipient-name" />
                                                                    </div>
                                                                    <div class="form-group">
                                                                        <label for="recipient-name" class="col-form-label">Ville</label>
                                                                        <input type="text" class="form-control" id="recipient-name" />
                                                                    </div>
                                                                    <div class="form-group">
                                                                        <select class="form-select" aria-label="Default select example">
                                                                            <option selected>Cliquer ici pour choisir la region du client</option>
                                                                            <option value="1">NORD</option>
                                                                            <option value="2">SUD</option>
                                                                            <option value="3">EST</option>
                                                                            <option value="4">OUEST</option>
                                                                            <option value="5">EXTREME NORD</option>
                                                                            <option value="6">ADAMAOUA</option>
                                                                            <option value="7">CENTRE</option>
                                                                            <option value="8">LITTORAL</option>
                                                                            <option value="9">SUD-OUEST</option>
                                                                            <option value="10">NORD-OUEST</option>
                                                                        </select>
                                                                    </div>
                                                                    <div class="form-floating">
                                                                        <textarea 
                                                                            class="form-control" 
                                                                            placeholder="Remplir votre description ici" 
                                                                            id="floatingTextarea2" 
                                                                            style={{height: "100px"}}
                                                                        />
                                                                        <label for="floatingTextarea2">Description</label>
                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="accordion-item">
                                                        <h2 class="accordion-header" id="headingTwo">
                                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                                Reservation
                                                            </button>
                                                        </h2>
                                                        <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                                            <div class="accordion-body">
                                                                <form>
                                                                    <div class="form-group">
                                                                        <select class="form-select" aria-label="Default select example">
                                                                            <option selected>Cliquer ici pour reserver une chambre</option>
                                                                            <option value="1">Chambre 1</option>
                                                                            <option value="2">Chambre 2</option>
                                                                            <option value="3">Chambre 3</option>
                                                                        </select>
                                                                    </div>
                                                                    <div class="form-group">
                                                                        <label for="recipient-name" class="col-form-label">Prix après la reduction</label>
                                                                        <input type="text" class="form-control" id="recipient-name" />
                                                                    </div>
                                                                    <div class="form-group">
                                                                        <label for="recipient-name" class="col-form-label">Pays de residence</label>
                                                                        <input type="text" class="form-control" id="recipient-name" />
                                                                    </div>
                                                                    <div class="form-group">
                                                                        <label for="recipient-name" class="col-form-label">Pays de residence</label>
                                                                        <input type="text" class="form-control" id="recipient-name" />
                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <div class="modal-footer">
                                                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fermer</button>
                                                <button type="button" class="btn btn-primary">Creer la reservation</button>
                                            </div>
                                        </div>    
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    {/* <Pagination
                        itemRender={itemRender}
                        defaultCurrent={currentPage}
                        onChange={handleChangeCurrentPage}
                        total={totalResult}
                    /> */}
                    <ReactPaginate
                        key={currentPage}
                        initialPage={currentPage}
                        previousLabel={"<"}
                        nextLabel={">"}
                        breakLabel={"..."}
                        pageCount={totalNumberOfPage}
                        marginPagesDisplayed={5}
                        pageRangeDisplayed={3}
                        onPageChange={handleChangeCurrentPage}
                        containerClassName={"pagination justify-content-center"}
                        forcePage={currentPage}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeLinkClassName={"active"}
                    />
                </div>
            </main>
            <div className="fixed-plugin">
                {/* <a 
                    href="/#"
                    className="fixed-plugin-button text-dark position-fixed px-3 py-2"
                >
                <i className="fa fa-cog py-2"> </i>
                </a> */}
                <div className="card shadow-lg ">
                    <div className="card-header pb-0 pt-3 ">
                        <div className="float-start">
                            <h5 className="mt-3 mb-0">Soft UI Configurator</h5>
                            <p>See our dashboard options.</p>
                        </div>
                        <div className="float-end mt-4">
                            <button className="btn btn-link text-dark p-0 fixed-plugin-close-button">
                                <i className="fa fa-close"></i>
                            </button>
                        </div>
                        {/* End Toggle Button */}
                    </div>
                    <hr className="horizontal dark my-1"/>
                    <div className="card-body pt-sm-3 pt-0">
                        {/*Sidebar Backgrounds */}
                        <div>
                        <h6 className="mb-0">Sidebar Colors</h6>
                        </div>
                        <a href={"javascript"} className="switch-trigger background-color">
                        <div className="badge-colors my-2 text-start">
                            <span className="badge filter bg-gradient-primary active" data-color="primary" onclick="sidebarColor(this)"></span>
                            <span className="badge filter bg-gradient-dark" data-color="dark" onclick="sidebarColor(this)"></span>
                            <span className="badge filter bg-gradient-info" data-color="info" onclick="sidebarColor(this)"></span>
                            <span className="badge filter bg-gradient-success" data-color="success" onclick="sidebarColor(this)"></span>
                            <span className="badge filter bg-gradient-warning" data-color="warning" onclick="sidebarColor(this)"></span>
                            <span className="badge filter bg-gradient-danger" data-color="danger" onclick="sidebarColor(this)"></span>
                        </div>
                        </a>
                        {/*Sidenav Type*/}
                        <div className="mt-3">
                        <h6 className="mb-0">Sidenav Type</h6>
                        <p className="text-sm">Choose between 2 different sidenav types.</p>
                        </div>
                        <div className="d-flex">
                        <button className="btn bg-gradient-primary w-100 px-3 mb-2 active" data-className="bg-transparent" onclick="sidebarType(this)">Transparent</button>
                        {/* <button className="btn bg-gradient-primary w-100 px-3 mb-2 ms-2" data-className="bg-white" onclick="sidebarType(this)">White</button> */}
                        </div>
                        <p className="text-sm d-xl-none d-block mt-2">You can change the sidenav type just on desktop view.</p>
                        {/* Navbar Fixed */}
                        <div className="mt-3">
                        <h6 className="mb-0">Navbar Fixed</h6>
                        </div>
                        <div className="form-check form-switch ps-0">
                        <input 
                            className="form-check-input mt-1 ms-auto" 
                            type="checkbox" 
                            id="navbarFixed" 
                            onclick="navbarFixed(this)"
                        />
                        </div>
                        <hr className="horizontal dark my-sm-4" />
                        <a className="btn bg-gradient-dark w-100" href="https://www.creative-tim.com/product/soft-ui-dashboard">Free Download</a>
                        <a className="btn btn-outline-dark w-100" href="https://www.creative-tim.com/learning-lab/bootstrap/license/soft-ui-dashboard">View documentation</a>
                        <div className="w-100 text-center">
                        <a className="github-button" href="https://github.com/creativetimofficial/soft-ui-dashboard" data-icon="octicon-star" data-size="large" data-show-count="true" aria-label="Star creativetimofficial/soft-ui-dashboard on GitHub">Star</a>
                        <h6 className="mt-3">Thank you for sharing!</h6>
                        <a 
                            href="https://twitter.com/intent/tweet?text=Check%20Soft%20UI%20Dashboard%20made%20by%20%40CreativeTim%20%23webdesign%20%23dashboard%20%23bootstrap5&amp;url=https%3A%2F%2Fwww.creative-tim.com%2Fproduct%2Fsoft-ui-dashboard" 
                            className="btn btn-dark mb-0 me-2"
                        >
                            <i className="fab fa-twitter me-1" aria-hidden="true"></i> Tweet
                        </a>
                        <a 
                            href={"https://www.facebook.com/sharer/sharer.php"} 
                            className="btn btn-dark mb-0 me-2"
                        >
                            <i className="fab fa-facebook-square me-1" aria-hidden="true" />Share
                        </a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="staticBackdropLabel">
                                Supprimer la reservation  
                                {/* {userUpdateOrDelete?.username } */}
                            </h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                    <div class="modal-body">
                        Etes-vous sur de vouloir supprimer 
                        {/* {userUpdateOrDelete?.username } */}
                    </div>
                    <div class="modal-footer">
                        <button 
                            type="button" 
                            className="btn btn-secondary" 
                            data-bs-dismiss="modal" 
                            ref={closeModalToDelete}
                        >
                            Non
                        </button>
                        <button 
                            type="button" 
                            class="btn btn-primary" 
                            onClick={() => bookingAdelete(bookingUpdateOrDelete.reference)}
                        >
                            Oui
                        </button>
                    </div>
                    </div>
                </div>
            </div>
            <div 
                // style={{zIndex: 200}} 
                class="modal fade modal-lg" 
                id="updateUserModal" 
                tabindex="-1" role="dialog" aria-labelledby="exampleUpdateUserModal" aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Modifier une Reservation</h5>
                            <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <Formik
                                enableReinitialize={true}
                                initialValues={{ 
                                    // firstName: bookingUpdateOrDelete ? bookingUpdateOrDelete.customer.firstName : "",
                                    // lastName: bookingUpdateOrDelete ? bookingUpdateOrDelete.customer.lastName : "",
                                    // gender: bookingUpdateOrDelete ? bookingUpdateOrDelete.customer.gender : "",
                                    // profession: bookingUpdateOrDelete ? bookingUpdateOrDelete.customer.profession :"",
                                    // country: bookingUpdateOrDelete ? bookingUpdateOrDelete.customer.country : "",
                                    // countryIdentityType: bookingUpdateOrDelete ? bookingUpdateOrDelete.customer.countryIdentityType : "",
                                    // countryIdentityNumber: bookingUpdateOrDelete ? bookingUpdateOrDelete.customer.countryIdentityNumber : "",
                                    // city: bookingUpdateOrDelete ? bookingUpdateOrDelete.customer.city :"",
                                    // region: bookingUpdateOrDelete ? bookingUpdateOrDelete.customer.region : "",
                                    // street: bookingUpdateOrDelete ? bookingUpdateOrDelete.customer.street : "",
                                    // address: bookingUpdateOrDelete ? bookingUpdateOrDelete.customer.address : "",
                                    // phoneNumber: bookingUpdateOrDelete ? bookingUpdateOrDelete.customer.phoneNumber : "",
                                    roomId: bookingUpdateOrDelete ? bookingUpdateOrDelete.room.id : "",
                                    startAt: bookingUpdateOrDelete ? bookingUpdateOrDelete.startAt : "",
                                    endAt: bookingUpdateOrDelete ? bookingUpdateOrDelete.endAt : "",
                                    type: bookingUpdateOrDelete ? bookingUpdateOrDelete.type : "",
                                    price: bookingUpdateOrDelete ? bookingUpdateOrDelete?.price : null,
                                    discount: bookingUpdateOrDelete ? bookingUpdateOrDelete?.discount : null,
                                    // earlyPayment: bookingUpdateOrDelete ? bookingUpdateOrDelete?.earlyPayment : null,
                                    // force: false,
                                }}
                                onSubmit={handleSubmitToUpdateBooking}
                                validateContext={{ totalAmount }}
                                
                                validate={(values) => {
                                    handleDateChange(values);
                                    handlePriceChange(values);
                                    handleDiscountChange(values);
                                }}
                                validationSchema={Yup.object().shape({
                                    // firstName: Yup.string(),
                                    // lastName: Yup.string()
                                    //     .required("Veuillez entrez le nom du client"),
                                    // gender: Yup.string()
                                    //     .oneOf(["masculin", "feminin"])
                                    //     .required("Veuillez choisir le genre"),
                                    // profession: Yup.string()
                                    //     .required("Veuillez entrer la profession"),
                                    // country: Yup.string()
                                    //     .required("Veuillez entrer le pays du client"),
                                    // countryIdentityType: Yup.string()
                                    //     .oneOf(["ID_CARD", "PASSPORT", "DRIVER_CARD"]),
                                    // countryIdentityNumber: Yup.number()
                                    //     .required("Veuillez entrer le numéro de la pièce d'identité"),
                                    // city: Yup.string()
                                    //     .required("Veuillez entrer la ville du client"),
                                    // region: Yup.string()
                                    //     .required("Veuillez renseigner la région du client"),
                                    // street: Yup.string()
                                    //     .required("Veuillez renseigner le quartier du client"),
                                    // address: Yup.string()
                                    //     .required("Veuillez renseigner l'adresse du client"),
                                    // phoneNumber: Yup.number()
                                    //     .required("Veuillez entrer le numéro de téléphone du client"),
                                    roomId: Yup.number()
                                        .required("Le numéro de la chambre est obligatoire"),  
                                    startAt: Yup.date()
                                        .required("Veuillez entrer la date de début"),
                                    endAt: Yup.date()
                                        .required("Veuillez entrer la date de sortie du client"), 
                                    type: Yup.string()
                                        .oneOf(["NORMAL", "NAP"])
                                        .required("Choisir le type de chambre"),
                                    price: Yup.number()
                                        .required("Le prix de la chambre est obligatoire"),  
                                    discount: Yup.number()
                                        .required('La réduction est requise')
                                        .max(totalAmount, `La réduction ne peut pas dépasser ${totalAmount}`),
                                    // earlyPayment: Yup.number()
                                    //     .required("Veuillez entrer le montant avancé par le client"),
                                    // force: Yup.boolean()
                                })}
                                validateOnBlur={false}
                                validateOnChange={true}
                                validateOnTouched={true}
                            >
                                {props => {
                                    const {
                                        values,
                                        touched,
                                        errors,
                                        dirty,
                                        setFieldValue,
                                        isSubmitting,
                                        handleChange,
                                        handleBlur,
                                        handleSubmit: handleSubmitToUpdateBooking,
                                        handleReset,
                                        
                                    } = props;
                                    
                                    const { nights, totalAmount, finalAmount } = calculteAmounts(values)
                                    // const handleFieldChange = (field, value) => {
                                    //     setFieldValue(field, value)
                                    //     const updatedValues = {...values, [field]: value};

                                    //     if (updatedValues.startAt && updatedValues.endAt) {
                                    //         const { nights, totalAmount, finalAmount } = calculteAmounts(
                                    //             updatedValues.startAt,
                                    //             updatedValues.endAt,
                                    //             updatedValues.price,
                                    //             updatedValues.discount
                                    //         )

                                    //         setFieldValue('numNights', nights)
                                    //         setFieldValue('totalAmount', totalAmount)
                                    //         setFieldValue('finalAmount', finalAmount)
                                    //     }
                                    // }
                                    
                                    return (
                                        <form onSubmit={handleSubmitToUpdateBooking}>
                                            {/* <div class="row mb-2">
                                                <div className="col-3">
                                                    <label 
                                                        htmlFor="firstName" 
                                                        className="col-form-label"
                                                    >
                                                        Prenom:
                                                    </label>
                                                </div>
                                                <div className="col-9">
                                                    <input 
                                                        id="firstName"
                                                        name="firstName"
                                                        value={values?.firstName}
                                                        // disabled={isFormDisabled} 
                                                        // onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={`form-control `}
                                                    />
                                                </div>
                                            </div>
                                            <div class="row mb-2">
                                                <div className="col-3">
                                                    <label 
                                                        htmlFor="lastName" 
                                                        className="col-form-label"
                                                    >
                                                        Nom:
                                                    </label>
                                                </div>
                                                <div className="col-9">
                                                    <input 
                                                        id="lastName"
                                                        name="lastName"
                                                        value={values?.lastName} 
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={`form-control ${
                                                            errors.lastName && touched.lastName
                                                            ? "text-input error"
                                                            : "text-input"
                                                        }`}
                                                    />
                                                </div>
                                            </div>
                                            {errors.lastName && touched.lastName && (
                                                <div className="input-feedback">{errors.lastName}</div>
                                            )}
                                            <div class="row mb-2">
                                                <div className="col-3">
                                                    <label 
                                                        htmlFor="gender" 
                                                        className="col-form-label"
                                                    >
                                                        Genre:
                                                    </label>
                                                </div>
                                                <div className="col-9">
                                                    <select 
                                                        id="gender"
                                                        name="gender"
                                                        value={values?.gender} 
                                                        onChange={handleChange}
                                                        className={`form-control ${
                                                            errors.gender && touched.gender
                                                            ? "text-input error"
                                                            : "text-input"
                                                        }`}
                                                    >
                                                        <option value="">Veuillez choisir le genre de la personne</option> 
                                                        <option value="masculin">HOMME</option>
                                                        <option value="feminin">FEMME</option>
                                                    </select>
                                                </div>
                                            </div>
                                            {errors.gender && touched.gender && (
                                                <div className="input-feedback">{errors.gender}</div>
                                            )}
                                            <div class="row mb-2">
                                                <div className="col-3">
                                                    <label 
                                                        htmlFor="profession" 
                                                        className="col-form-label"
                                                    >
                                                        Profession:
                                                    </label>
                                                </div>
                                                <div className="col-9">
                                                    <input 
                                                        id="profession"
                                                        name="profession"
                                                        value={values?.profession} 
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={`form-control ${
                                                            errors.profession && touched.profession
                                                            ? "text-input error"
                                                            : "text-input"
                                                        }`}
                                                    />
                                                </div>
                                            </div>
                                            {errors.profession && touched.profession && (
                                                <div className="input-feedback">{errors.profession}</div>
                                            )}
                                            <div class="row mb-2">
                                                <div className="col-3">
                                                    <label 
                                                        htmlFor="country" 
                                                        className="col-form-label"
                                                    >
                                                        Pays du client:
                                                    </label>
                                                </div>
                                                <div className="col-9">
                                                    <input 
                                                        id="country"
                                                        name="country"
                                                        value={values?.country} 
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={`form-control ${
                                                            errors.country && touched.country
                                                            ? "text-input error"
                                                            : "text-input"
                                                        }`}
                                                    />
                                                </div>
                                            </div>
                                            {errors.country && touched.country && (
                                                <div className="input-feedback">{errors.country}</div>
                                            )}
                                            <div class="row mb-2">
                                                <div className="col-3">
                                                    <label 
                                                        htmlFor="countryIdentityType" 
                                                        className="col-form-label"
                                                    >
                                                        Type d'identité du pays:
                                                    </label>
                                                </div>
                                                <div className="col-9">
                                                    <select 
                                                        id="countryIdentityType"
                                                        name="countryIdentityType"
                                                        type="text"
                                                        value={values?.countryIdentityType} 
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={`form-control ${
                                                            errors.countryIdentityType && touched.countryIdentityType
                                                            ? "text-input error"
                                                            : "text-input"
                                                        }`}
                                                    >
                                                        <option value="">Veuillez selectionné un moyenne d'identification</option>
                                                        <option value="ID_CARD">CARTE D'IDENTITE</option>
                                                        <option value="PASSPORT">PASSEPORT</option>
                                                        <option value="DRIVER_CARD">PERMIS DE CONDUIRE</option>
                                                    </select>
                                                </div>
                                            </div>
                                            {errors.countryIdentityType && touched.countryIdentityType && (
                                                <div className="input-feedback">{errors.countryIdentityType}</div>
                                            )}
                                            <div class="row mb-2">
                                                <div className="col-3">
                                                    <label 
                                                        htmlFor="countryIdentityNumber" 
                                                        className="col-form-label"
                                                    >
                                                        Numéro d'identité dans son pays:
                                                    </label>
                                                </div>
                                                <div className="col-9">
                                                    <input 
                                                        id="countryIdentityNumber"
                                                        name="countryIdentityNumber"
                                                        type="number"
                                                        value={values?.countryIdentityNumber} 
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={`form-control ${
                                                            errors.countryIdentityNumber && touched.countryIdentityNumber
                                                            ? "text-input error"
                                                            : "text-input"
                                                        }`}
                                                    />
                                                </div>
                                            </div>
                                            {errors.countryIdentityNumber && touched.countryIdentityNumber && (
                                                <div className="input-feedback">{errors.countryIdentityNumber}</div>
                                            )}
                                            <div class="row mb-2">
                                                <div className="col-3">
                                                    <label 
                                                        htmlFor="city" 
                                                        className="col-form-label"
                                                    >
                                                        Ville du client:
                                                    </label>
                                                </div>
                                                <div className="col-9">
                                                    <input 
                                                        id="city"
                                                        name="city"
                                                        type="text"
                                                        value={values?.city} 
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={`form-control ${
                                                            errors.city && touched.city
                                                            ? "text-input error"
                                                            : "text-input"
                                                        }`}
                                                    />
                                                </div>
                                            </div>
                                            {errors.city && touched.city && (
                                                <div className="input-feedback">{errors.city}</div>
                                            )}
                                            <div class="row mb-2">
                                                <div className="col-3">
                                                    <label 
                                                        htmlFor="region" 
                                                        className="col-form-label"
                                                    >
                                                        Région du client:
                                                    </label>
                                                </div>
                                                <div className="col-9">
                                                    <input 
                                                        id="region"
                                                        name="region"
                                                        type="text"
                                                        value={values?.region} 
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={`form-control ${
                                                            errors.region && touched.region
                                                            ? "text-input error"
                                                            : "text-input"
                                                        }`}
                                                    />
                                                </div>
                                            </div>
                                            {errors.region && touched.region && (
                                                <div className="input-feedback">{errors.region}</div>
                                            )}
                                            <div class="row mb-2">
                                                <div className="col-3">
                                                    <label 
                                                        htmlFor="street" 
                                                        className="col-form-label"
                                                    >
                                                        Quartier:
                                                    </label>
                                                </div>
                                                <div className="col-9">
                                                    <input 
                                                        id="street"
                                                        name="street"
                                                        type="text"
                                                        value={values?.street} 
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={`form-control ${
                                                            errors.street && touched.street
                                                            ? "text-input error"
                                                            : "text-input"
                                                        }`}
                                                    />
                                                </div>
                                            </div>
                                            {errors.street && touched.street && (
                                                <div className="input-feedback">{errors.street}</div>
                                            )}
                                            <div class="row mb-2">
                                                <div className="col-3">
                                                    <label 
                                                        htmlFor="address" 
                                                        className="col-form-label"
                                                    >
                                                        Adresse du client:
                                                    </label>
                                                </div>
                                                <div className="col-9">
                                                    <input 
                                                        id="address"
                                                        name="address"
                                                        type="text"
                                                        value={values?.address} 
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={`form-control ${
                                                            errors.address && touched.address
                                                            ? "text-input error"
                                                            : "text-input"
                                                        }`}
                                                    />
                                                </div>
                                            </div>
                                            {errors.address && touched.address && (
                                                <div className="input-feedback">{errors.address}</div>
                                            )}
                                            <div class="row mb-2">
                                                <div className="col-3">
                                                    <label 
                                                        htmlFor="phoneNumber" 
                                                        className="col-form-label"
                                                    >
                                                        Telephone du client:
                                                    </label>
                                                </div>
                                                <div className="col-9">
                                                    <input 
                                                        id="phoneNumber"
                                                        name="phoneNumber"
                                                        type="number"
                                                        value={values?.phoneNumber} 
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={`form-control ${
                                                            errors.phoneNumber && touched.phoneNumber
                                                            ? "text-input error"
                                                            : "text-input"
                                                        }`}
                                                    />
                                                </div>
                                            </div>
                                            {errors.phoneNumber && touched.phoneNumber && (
                                                <div className="input-feedback">{errors.phoneNumber}</div>
                                            )} */}
                                            
                                            <div className="row mb-2">
                                                <div className="col-3">
                                                    <label 
                                                        htmlFor="startAt" 
                                                        className="col-form-label"
                                                    >
                                                        Date de début
                                                    </label>
                                                </div>
                                                <div className="col-9">
                                                    <DatePicker
                                                        selected={values.startAt ? new Date(values.startAt) : null}
                                                        onChange={(date) => {
                                                            const selectedDate = moment(date);
                                                            const finalDate = selectedDate.toDate();
                                                            setFieldValue('startAt', finalDate);
                                                            handleDateChange({ ...values, startAt: finalDate });
                                                        }}
                                                        onBlur={handleBlur}
                                                        showTimeSelect
                                                        timeFormat="HH:mm"
                                                        timeIntervals={15}
                                                        locale="fr"
                                                        dateFormat="d MMMM yyyy 'à' HH:mm"
                                                        className={`form-control dateWith ${
                                                            errors.startAt && touched.startAt ? "text-input error" : "text-input"
                                                        }`}
                                                        showTimeInput={true}
                                                    />
                                                    {/* <input 
                                                        type="date" 
                                                        id="startAt"
                                                        name="startAt"
                                                        value={values.startAt} 
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={`form-control ${
                                                            errors.startAt && touched.startAt
                                                            ? "text-input error"
                                                            : "text-input"
                                                        }`}
                                                    /> */}
                                                </div>
                                            </div>
                                            {errors.startAt && touched.startAt && (
                                                <div className="input-feedback">{errors.startAt}</div>
                                            )}
                                            <div className="row mb-2">
                                                <div className="col-3">
                                                    <label 
                                                        htmlFor="endAt" 
                                                        className="col-form-label"
                                                    >
                                                        Date de fin
                                                    </label>
                                                </div>
                                                <div className="col-9">
                                                    {/* <DatePicker
                                                        selected={values.endAt ? new Date(values.endAt) : null}
                                                        onChange={(date) => {
                                                            const selectedHour = values.endAt ? new Date(values.endAt).getHours() : 0;
                                                            const selectedMinute = values.endAt ? new Date(values.endAt).getMinutes() : 0;
                                                            const newDate = new Date(date.getFullYear(), date.getMonth(), date.getDate(), selectedHour, selectedMinute);
                                                            setFieldValue('endAt', newDate);
                                                            handleDateChange({ ...values, endAt: newDate });
                                                        }}
                                                        onBlur={handleBlur}
                                                        showTimeSelect
                                                        dateFormat="d MMMM yyyy 'à' HH:mm"
                                                        timeFormat="HH:mm"
                                                        timeIntervals={15}
                                                        timeCaption="Heure"
                                                        locale="fr"
                                                        className={`form-control dateWith ${
                                                            errors.endAt && touched.endAt ? "text-input error" : "text-input"
                                                        }`}
                                                    /> */}
                                                    <DatePicker
                                                        selected={values.endAt ? new Date(values.endAt) : null}
                                                        onChange={(date) => {
                                                            setFieldValue('endAt', date);
                                                            handleDateChange({ ...values, endAt: date });
                                                        }}
                                                        onBlur={handleBlur}
                                                        showTimeSelect
                                                        dateFormat="d MMMM yyyy à HH:mm"
                                                        timeFormat="HH:mm"
                                                        locale="fr"
                                                        className={`form-control dateWith ${
                                                            errors.endAt && touched.endAt ? "text-input error" : "text-input"
                                                        }`}
                                                    />
                                                    {/* <DatePicker
                                                        selected={values.endAt ? new Date(values.endAt) : null}
                                                        onChange={(date) => {
                                                            setFieldValue('endAt', date);
                                                            handleDateChange({ ...values, endAt: date });
                                                        }}
                                                        onBlur={handleBlur}
                                                        showTimeSelect
                                                        dateFormat="d MMMM yyyy 'à' HH:mm"
                                                        timeFormat="HH:mm"
                                                        timeIntervals={15}
                                                        timeCaption="Heure"
                                                        locale="fr"
                                                        className={`form-control dateWith ${
                                                            errors.endAt && touched.endAt ? "text-input error" : "text-input"
                                                        }`}
                                                    /> */}
                                                    {/* <DatePicker
                                                            selected={values.endAt ? new Date(values.endAt) : null}
                                                            onChange={(date) => {
                                                                setFieldValue('endAt', date);
                                                                handleDateChange({ ...values, endAt: date });
                                                            }}
                                                            onBlur={handleBlur}
                                                            showTimeSelect
                                                            dateFormat="d MMMM yyyy 'à' HH:mm"
                                                            timeFormat="HH:mm"
                                                            timeIntervals={15}
                                                            timeCaption="Heure"
                                                            locale="fr"
                                                            className={`form-control dateWith ${
                                                                errors.endAt && touched.endAt ? "text-input error" : "text-input"
                                                            }`}
                                                        /> */}
                                                    {/* <DatePicker
                                                        selected={values.endAt ? new Date(values.endAt) : null}
                                                        onChange={(date) => {
                                                            setFieldValue('endAt', date);
                                                            handleDateChange({ ...values, endAt: date });
                                                        }}
                                                        onBlur={handleBlur}
                                                        showTimeSelect
                                                        dateFormat="Pp"
                                                        className={`form-control dateWith ${
                                                            errors.endAt && touched.endAt ? "text-input error" : "text-input"
                                                        }`}
                                                    /> */}
                                                </div>
                                            </div>
                                            {errors.endAt && touched.endAt && (
                                                <div className="input-feedback">{errors.endAt}</div>
                                            )}
                                            <div className="row mb-2">
                                                <div className="col-3">
                                                    <label htmlFor="nights" style={{fontSize: "16px"}} className="col-form-label">Nombre de nuitées</label>
                                                </div>
                                                <div className="col-9">
                                                    <input
                                                        type="text"
                                                        id="nights"
                                                        name="nights"
                                                        value={nights}
                                                        readOnly
                                                        disabled
                                                        className="form-control"
                                                    />
                                                </div>
                                            </div>
                                            <div class="row mb-2">
                                                <div className="col-3">
                                                    <label 
                                                        htmlFor="roomId" 
                                                        className="col-form-label"
                                                    >
                                                        Nom de la chambre:
                                                    </label>
                                                </div>
                                                <div className="col-9">
                                                {rooms && rooms.length > 0 ? (
                                                    <Field name="roomId">
                                                    {({ field }) => (
                                                        <Select 
                                                            {...field}
                                                            showSearch
                                                            id="roomId"
                                                            placeholder="Sélectionner une chambre"
                                                            optionFilterProp="children"
                                                            name="roomId"
                                                            value={values.roomId} 
                                                            onChange={(value) => {
                                                                //const roomId = parseInt(event.target.value, 10);
                                                                console.log('dans onchange ==> ', value)
                                                                //console.log('dans onchange ==> ', values)
                                                                const room = rooms.find((room) => room.id === value);
                                                                setFieldValue('roomId', value);
                                                                setFieldValue('price', room.price);
                                                            }}
                                                            onBlur={handleBlur}
                                                            filterOption={(input, option) =>
                                                                option.children.toLowerCase().includes(input.toLowerCase())
                                                            }
                                                            style={{ width: '100%', zIndex: 2000}}
                                                        >   
                                                            <Option value="">
                                                                Veuillez entrez le numéro le numéro de la chambre
                                                            </Option>
                                                            {rooms.map((room => (
                                                                <Option key={room.id} value={room.id}>
                                                                    {room.number}
                                                                </Option>
                                                            )))}
                                                        </Select>
                                                    )} 
                                                </Field>
                                                ) : (
                                                    <p>Chargement des chambres...</p>)
                                                }
                                                    {/* <select 
                                                            id="roomId"
                                                            name="roomId"
                                                            value={values.roomId} 
                                                            onChange={(event) => {
                                                                const roomId = parseInt(event.target.value, 10);
                                                                const room = rooms.find((room) => room.id === roomId);
                                                                setFieldValue('roomId', roomId);
                                                                setFieldValue('price', room.price);
                                                            }}
                                                            onBlur={handleBlur}
                                                            className={`form-control form-select`}
                                                            aria-label="Default select example"
                                                        >
                                                            <option value="">Veuillez Choisir la chambre à reserver</option>
                                                            {rooms.map(room => (
                                                                <option value={room.id}>{room.number}</option>
                                                            ))}
                                                            
                                                        </select> */} 
                                                </div>
                                            </div>
                                            {errors.roomId && touched.roomId && (
                                                <div className="input-feedback">{errors.roomId}</div>
                                            )}
                                            <div class="row mb-2">
                                                <div className="col-3">
                                                    <label 
                                                        htmlFor="type" 
                                                        className="col-form-label"
                                                    >
                                                        Type de reservation:
                                                    </label>
                                                </div>
                                                <div className="col-9">
                                                    <select 
                                                        id="type"
                                                        name="type"
                                                        value={values.type} 
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={`form-control ${
                                                            errors.type && touched.type
                                                            ? "text-input error"
                                                            : "text-input"
                                                        }`}
                                                        aria-label="Default select example"
                                                    >
                                                        <option value="NORMAL">NORMAL</option>
                                                        <option value="NAP">SIESTE</option>
                                                        
                                                    </select>
                                                </div>
                                            </div>
                                            {errors.type && touched.type && (
                                                <div className="input-feedback">{errors.type}</div>
                                            )}
                                            <div class="row mb-2">
                                                <div className="col-3">
                                                    <label 
                                                        htmlFor="price" 
                                                        className="col-form-label"
                                                    >
                                                        Prix de la reservation:
                                                    </label>
                                                </div>
                                                <div className="col-9">
                                                    <input 
                                                        type="number"
                                                        id="price" 
                                                        name="price"
                                                        value={values.price} 
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={`form-control ${
                                                            errors.price && touched.price
                                                            ? "text-input error"
                                                            : "text-input"
                                                        }`}
                                                    />
                                                </div>
                                            </div>
                                            {errors.price && touched.price && (
                                                <div className="input-feedback">{errors.price}</div>
                                            )}
                                            <div className="row mb-2">
                                                <div className="col-3">
                                                    <label 
                                                        htmlFor="totalAmount" 
                                                        style={{fontSize: "16px"}} 
                                                        className="col-form-label"
                                                    >
                                                        Montant total
                                                    </label>
                                                </div>
                                                <div className="col-9">
                                                    <input
                                                        type="text"
                                                        id="totalAmount"
                                                        name="totalAmount"
                                                        value={totalAmount}
                                                        readOnly
                                                        disabled
                                                        className="form-control"
                                                    />
                                                </div>
                                            </div>
                                            <div class="row mb-2">
                                                <div className="col-3">
                                                    <label 
                                                        htmlFor="discount" 
                                                        className="col-form-label"
                                                    >
                                                        Reduction:
                                                    </label>
                                                </div>
                                                <div className="col-9">
                                                    <input 
                                                        type="number"
                                                        id="discount" 
                                                        name="discount"
                                                        value={values.discount} 
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={`form-control ${
                                                            errors.discount && touched.discount
                                                            ? "text-input error"
                                                            : "text-input"
                                                        }`}
                                                    />
                                                </div>
                                            </div>
                                            {errors.discount && touched.discount && (
                                                <div className="input-feedback">{errors.discount}</div>
                                            )}
                                            {/* <div class="row mb-2">
                                                <div className="col-3">
                                                    <label 
                                                        htmlFor="earlyPayment" 
                                                        className="col-form-label"
                                                    >
                                                        Payement anticipé:
                                                    </label>
                                                </div>
                                                <div className="col-9">
                                                    <input 
                                                        type="number"
                                                        id="earlyPayment" 
                                                        name="earlyPayment"
                                                        value={values.earlyPayment} 
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={`form-control ${
                                                            errors.earlyPayment && touched.earlyPayment
                                                            ? "text-input error"
                                                            : "text-input"
                                                        }`}
                                                    />
                                                </div>
                                            </div>
                                            {errors.earlyPayment && touched.earlyPayment && (
                                                <div className="input-feedback">{errors.earlyPayment}</div>
                                            )} */}
                                            {/* <div class="row mb-2">
                                                <div className="col-3">
                                                    <label 
                                                        htmlFor="force" 
                                                        className="col-form-label"
                                                    >
                                                        Forcer la reservation:
                                                    </label>
                                                </div>
                                                <div className="col-9">
                                                    <select 
                                                        defaultValue={false}
                                                        id="force"
                                                        name="force"
                                                        value={values.force} 
                                                        onChange={(e) => {
                                                            const value = e.target.value === 'true';
                                                            setFieldValue('force', value);
                                                        }}
                                                        // onBlur={}
                                                        className={`form-control form-select`}
                                                    >
                                                        <option value="false">NON</option>
                                                        <option value="true">OUI</option>
                                                        
                                                    </select>
                                                </div>
                                            </div> */}
                                            
                                            
                                            <div className="row mb-2">
                                                <div className="col-3">
                                                    <label htmlFor="finalAmount" style={{fontSize: "16px"}} className="col-form-label">Montant après réduction</label>
                                                </div>
                                                <div className="col-9">
                                                    <input
                                                        type="text"
                                                        id="finalAmount"
                                                        name="finalAmount"
                                                        value={finalAmount}
                                                        disabled
                                                        readOnly
                                                        className="form-control"
                                                    />
                                                </div>
                                            </div>
                                            <div className="d-flex justify-content-center">
                                                <button 
                                                    type="button" 
                                                    ref={closeModalToUpdate}
                                                    className="btn btn-secondary" 
                                                    data-bs-dismiss="modal"
                                                >
                                                    Close
                                                </button>
                                                <button 
                                                    type="submit" 
                                                    className="btn btn-primary "
                                                >
                                                    Modifier une Reservation
                                                </button>
                                                {/* <button 
                                                    type="button" 
                                                    className="btn btn-secondary"
                                                    data-bs-toggle="offcanvas" 
                                                    data-bs-target="#offcanvasBottom" 
                                                    aria-controls="offcanvasBottom"
                                                >
                                                    Voir info reservation
                                                </button> */}
                                            </div>
                                        </form>
                                    )
                                }}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div class="offcanvas offcanvas-bottom" tabindex="-1" id="offcanvasBottom" aria-labelledby="offcanvasBottomLabel">
                <div class="offcanvas-header">
                    <h5 class="offcanvas-title" id="offcanvasBottomLabel">Information sur la reservation</h5>
                    <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div class="offcanvas-body small">
                    <div className="row mb-2">
                        <div className="col-3">
                            <label htmlFor="nights" style={{fontSize: "16px"}} className="col-form-label">Nombre de nuitées</label>
                        </div>
                        <div className="col-9">
                            <input
                                type="text"
                                id="nights"
                                name="nights"
                                value={nights}
                                readOnly
                                className="form-control"
                            />
                        </div>
                    </div>
                    <div className="row mb-2">
                        <div className="col-3">
                            <label htmlFor="totalAmount" style={{fontSize: "16px"}} className="col-form-label">Montant total</label>
                        </div>
                        <div className="col-9">
                            <input
                                type="text"
                                id="totalAmount"
                                name="totalAmount"
                                value={totalAmount}
                                readOnly
                                className="form-control"
                            />
                        </div>
                    </div>
                    <div className="row mb-2">
                        <div className="col-3">
                            <label htmlFor="finalAmount" style={{fontSize: "16px"}} className="col-form-label">Montant après réduction</label>
                        </div>
                        <div className="col-9">
                            <input
                                type="text"
                                id="finalAmount"
                                name="finalAmount"
                                value={finalAmount}
                                readOnly
                                className="form-control"
                            />
                        </div>
                    </div>
                </div>
            </div> */}
                <div class="modal fade" id="paymentModal" tabindex="-1" aria-labelledby="paymentModalLabel" aria-hidden="true">
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="paymentModalLabel">Paiement</h5>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                                <Formik
                                    initialValues={{
                                        //remainingAmount: bookingUpdateOrDelete?.remainingAmount,
                                        //totalPaidAmount: bookingUpdateOrDelete?.totalPaidAmount,
                                        //totalAmount: bookingUpdateOrDelete?.totalAmount,
                                        amount: 0,
                                    }}
                                    validationSchema={AmountPaymentSchema}
                                    validateOnChange={true}
                                    validateOnBlur={false}
                                    onSubmit={paymentInstallment}
                                >
                                    {({ values, errors, touched, handleChange, handleBlur, handleSubmit: paymentInstallment }) => (
                                        <form onSubmit={paymentInstallment}>
                                            {/* <div className="mb-3">
                                                <label htmlFor="remainingAmount" className="form-label">
                                                    Montant Restant
                                                </label>
                                                <input
                                                    type="number"
                                                    className={`form-control ${errors.amount && touched.amount ? 'is-invalid' : ''}`}
                                                    id="remainingAmount"
                                                    name="remainingAmount"
                                                    disabled
                                                    value={values.remainingAmount}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                                {errors.amount && touched.amount && (
                                                    <div className="invalid-feedback">{errors.amount}</div>
                                                )}
                                            </div>
                                            <div className="mb-3">
                                                <label htmlFor="totalPaidAmount" className="form-label">
                                                    Montant payé
                                                </label>
                                                <input
                                                    type="number"
                                                    className={`form-control ${errors.amount && touched.amount ? 'is-invalid' : ''}`}
                                                    id="totalPaidAmount"
                                                    name="totalPaidAmount"
                                                    disabled
                                                    value={values.totalPaidAmount}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                                {errors.amount && touched.amount && (
                                                    <div className="invalid-feedback">{errors.amount}</div>
                                                )}
                                            </div>
                                            <div className="mb-3">
                                                <label htmlFor="totalAmount" className="form-label">
                                                    Montant Total
                                                </label>
                                                <input
                                                    type="number"
                                                    className={`form-control ${errors.amount && touched.amount ? 'is-invalid' : ''}`}
                                                    id="totalAmount"
                                                    name="totalAmount"
                                                    value={values.totalAmount}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                                {errors.amount && touched.amount && (
                                                    <div className="invalid-feedback">{errors.amount}</div>
                                                )}
                                            </div> */}
                                            <div className="mb-3">
                                                <label htmlFor="amount" className="form-label">
                                                    Montant 
                                                </label>
                                                <input
                                                    type="number"
                                                    className={`form-control ${errors.amount && touched.amount ? 'is-invalid' : ''}`}
                                                    id="amount"
                                                    name="amount"
                                                    value={values.amount}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                                {errors.amount && touched.amount && (
                                                    <div className="invalid-feedback">{errors.amount}</div>
                                                )}
                                            </div>
                                            <div className="modal-footer">
                                                <button 
                                                    type="button" 
                                                    className="btn btn-secondary" 
                                                    ref={closePaymentModal}
                                                    data-bs-dismiss="modal"
                                                >
                                                    Fermer
                                                </button>
                                                <button type="submit" className="btn btn-primary">
                                                    Payer
                                                </button>
                                            </div>
                                        </form>
                                    )}
                                </Formik>
                            </div>
                            
                        </div>
                    </div>
                </div>
                <div
                    className="modal fade"
                    id="reservationFactureModal"
                    //data-bs-backdrop="static"
                    //data-bs-keyboard="false" 
                    tabindex="-1"
                    aria-labelledby="reservationFactureModalLabel"
                    aria-hidden="true"
                >
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                            <h5 className="modal-title" id="reservationModalLabel">
                                Détails de la réservation
                            </h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                
                                    <div>
                                        <h5 className="text-primary">Informations du client</h5>
                                        <p>Nom: {bookingUpdateOrDelete?.customer.firstName}</p>
                                        <p>Sexe: {bookingUpdateOrDelete?.customer.gender}</p>
                                        <p>Téléphone: {bookingUpdateOrDelete?.customer.phoneNumber}</p>

                                        <h5 className="text-primary">Informations de la chambre</h5>
                                        <p>Numéro de chambre: {bookingUpdateOrDelete?.room.number}</p>
                                        <p>Type de chambre: {bookingUpdateOrDelete?.room.type}</p>
                                        <p>Nombre de jours: {bookingUpdateOrDelete?.numberOfDays}</p>

                                        <h5 className="text-primary">Informations sur le bilan de paiement</h5>
                                        <p>Montant total: {bookingUpdateOrDelete?.totalPrice}</p>
                                        <p>Montant payé: {bookingUpdateOrDelete?.totalPaidAmount}</p>
                                        <p>Montant restant: {bookingUpdateOrDelete?.remainingAmount}</p> 
                                    </div>
                            
                                
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    data-bs-dismiss="modal"
                                >
                                    Fermer
                                </button>
                                <PDFDownloadLink
                                    document={<Invoice reservation={bookingUpdateOrDelete} />}
                                    fileName="facture.pdf"
                                >
                                    {({ loading }) => (loading ? 'Chargement du document...' : 'Imprimer la facture')}
                                </PDFDownloadLink>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div class="modal fade" id="staticChangePasswordBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticChangePasswordBackdropLabel" aria-hidden="true">
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="staticBackdropLabel">Modal title</h5>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                                <Formik
                                    initialValues={{ currentPassword: '', newPassword: '' }}
                                    validationSchema={PasswordChangeSchema}
                                    onSubmit={(values, { setSubmitting }) => {
                                        // Logique de soumission du formulaire
                                        alert(values);
                                        setSubmitting(false);
                                    }}
                                >
                                    {({ isSubmitting }) => (
                                        <Form>
                                            <div className="form-group">
                                                <label htmlFor="currentPassword">Mot de passe actuel</label>
                                                <Field
                                                    type="password"
                                                    name="currentPassword"
                                                    className="form-control"
                                                    id="currentPassword"
                                                />
                                                <ErrorMessage
                                                    name="currentPassword"
                                                    component="div"
                                                    className="text-danger"
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="newPassword">Nouveau mot de passe</label>
                                                <Field
                                                    type="password"
                                                    name="newPassword"
                                                    className="form-control"
                                                    id="newPassword"
                                                />
                                                <ErrorMessage
                                                    name="newPassword"
                                                    component="div"
                                                    className="text-danger"
                                                />
                                            </div>
                                            <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
                                                Changer mot de passe
                                            </button>
                                        </Form>
                                    )}
                                </Formik>
                            </div>
                            
                        </div>
                    </div>
                </div> */}
                <div class="modal fade" id="staticChangePasswordBackdrop" tabindex="-1" aria-labelledby="staticChangePasswordBackdropLabel" aria-hidden="true">
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="paymentModalLabel">Modifiez le mot de passe actuel</h5>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                                <Formik
                                    initialValues={{
                                        currentPassword: '',
                                        newPassword: ''
                                    }}
                                    validationSchema={PasswordChangeSchema}
                                    validateOnChange={true}
                                    validateOnBlur={false}
                                    onSubmit={changePassword}
                                >
                                    {({ values, errors, touched, handleChange, handleBlur, handleSubmit: changePassword }) => (
                                        <form onSubmit={changePassword}>
                                            <div className="mb-3">
                                                <label htmlFor="amount" className="form-label">
                                                    Mot de passe actuel
                                                </label>
                                                <input
                                                    type="text"
                                                    className={`form-control ${errors.currentPassword && touched.currentPassword ? 'is-invalid' : ''}`}
                                                    id="currentPassword"
                                                    name="currentPassword"
                                                    value={values.currentPassword}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                                {errors.currentPassword && touched.currentPassword && (
                                                    <div className="invalid-feedback">{errors.currentPassword}</div>
                                                )}
                                            </div>
                                            <div>
                                            <label htmlFor="newPassword" className="form-label">
                                                    Nouveau password
                                                </label>
                                                <input
                                                    type="text"
                                                    className={`form-control ${errors.newPassword && touched.newPassword ? 'is-invalid' : ''}`}
                                                    id="newPassword"
                                                    name="newPassword"
                                                    value={values.newPassword}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                                {errors.newPassword && touched.newPassword && (
                                                    <div className="invalid-feedback">{errors.newPassword}</div>
                                                )}
                                            </div>
                                        <div className="modal-footer">
                                            <button 
                                                type="button" 
                                                className="btn btn-secondary" 
                                                data-bs-dismiss="modal"
                                                ref={closeModalToChangePassword}
                                            >
                                                Fermer
                                            </button>
                                            <button type="submit" className="btn btn-primary">
                                                Modifier
                                            </button>
                                        </div>
                                        </form>
                                    )}
                                </Formik>
                            </div>
                            
                        </div>
                    </div>
                </div>
                <div class="modal fade" id="staticGlobaleBilanBackdrop" tabindex="-1" aria-labelledby="staticGlobaleBilanBackdropLabel" aria-hidden="true">
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="paymentModalLabel">Bilan globale</h5>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                                <Formik
                                    initialValues={{
                                        startDate: '',
                                        endDate: '',
                                        filterIncompletePayments: false
                                    }}
                                    validationSchema={validationGlobalBilanSchema}
                                    validateOnChange={true}
                                    validateOnBlur={false}
                                    onSubmit={globalBilanWithAndWithoutIncompletePayment}
                                >
                                    {({ values, errors, touched, handleChange, handleBlur, handleSubmit: globalBilanWithAndWithoutIncompletePayment }) => (
                                        <form onSubmit={globalBilanWithAndWithoutIncompletePayment}>
                                            <div className="mb-3">
                                                <label htmlFor="startDate" className="form-label">
                                                    Date de début
                                                </label>
                                                <input
                                                    type="date"
                                                    className={`form-control ${errors.startDate && touched.startDate ? 'is-invalid' : ''}`}
                                                    id="startDate"
                                                    name="startDate"
                                                    value={values.startDate}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                                {errors.startDate && touched.startDate && (
                                                    <div className="invalid-feedback">{errors.startDate}</div>
                                                )}
                                            </div>
                                            <div>
                                                <label htmlFor="endDate" className="form-label">
                                                    Date de fin
                                                </label>
                                                <input
                                                    type="date"
                                                    className={`form-control ${errors.endDate && touched.endDate ? 'is-invalid' : ''}`}
                                                    id="endDate"
                                                    name="endDate"
                                                    value={values.endDate}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                                {errors.endDate && touched.endDate && (
                                                    <div className="invalid-feedback">{errors.endDate}</div>
                                                )}
                                            </div>
                                            <div>
                                                <label htmlFor="filterIncompletePayments" className="form-label">
                                                    Filtrer les paiements incomplet
                                                </label>
                                                <select
                                                    className={`form-select ${errors.endDate && touched.endDate ? 'is-invalid' : ''}`}
                                                    id="filterIncompletePayments"
                                                    name="filterIncompletePayments"
                                                    value={values.filterIncompletePayments}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                >
                                                    <option value={false}>false</option>
                                                    <option value={true}>true</option>
                                                </select>
                                                {errors.filterIncompletePayments && touched.filterIncompletePayments && (
                                                    <div className="invalid-feedback">{errors.filterIncompletePayments}</div>
                                                )}
                                            </div>
                                        <div className="modal-footer">
                                            <button 
                                                type="button" 
                                                className="btn btn-secondary" 
                                                ref={closeModalToChangePassword}
                                                data-bs-dismiss="modal"
                                            >
                                                Fermer
                                            </button>
                                            <button type="submit" className="btn btn-primary">
                                                Imprimer le bilan globale
                                            </button>
                                        </div>
                                        </form>
                                    )}
                                </Formik>
                            </div>
                            
                        </div>
                    </div>
                </div>
                <div
                    className="modal fade"
                    id="terminateModal"
                    tabindex="-1"
                    //style={{ display: "block" }} 
                    aria-labelledby="terminateModalLabel"
                    aria-hidden="true"
                >
                    <div className="modal-dialog">
                        <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="terminateModalLabel">
                            Terminer la réservation de {bookingUpdateOrDelete?.customer?.lastName}
                            </h5>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            //onClick={closeModal}
                            ></button>
                        </div>
                        <div className="modal-body">
                            Êtes-vous sûr de vouloir terminer cette réservation ?
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-secondary"
                                data-bs-dismiss="modal"
                                ref={closeModalToTerminate}
                                //onClick={closeModal}
                            >
                                Annuler
                            </button>
                            <button
                                type="button"
                                className="btn btn-danger"
                                onClick={() => confirmTerminate(bookingUpdateOrDelete.reference)}
                            >
                                Confirmer
                            </button>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
        
        
    )
}
export default Booking;